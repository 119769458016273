// src/ChevronUp.tsx
import { createIcon } from "@chakra-ui/icon";
var ChevronUpIcon = createIcon({
  d: "M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z",
  displayName: "ChevronUpIcon"
});

export {
  ChevronUpIcon
};
