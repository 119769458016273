import React, {PropsWithChildren, useContext, useEffect} from 'react';
import {useWindowSize} from 'react-use';
import {
  Heading,
  Text,
  VStack,
  Button,
  Link,
  ListItem,
  UnorderedList,
  HStack, LinkProps
} from '@chakra-ui/react';
import {px} from '../theme/px';
import {Spacing04, Spacing05, Spacing06, Spacing08} from '@RHEnvironmental/pattern-library-tokens';
import {SearchContext} from '../search/SearchContext';
import {Trans, useTranslation} from 'react-i18next';
import {breakpoints} from '../theme/breakpoints';

const textStyle = 'textBodyRegular2'

const MarketingPage = () => {
  const {setIsSearchPanelOpen} = useContext(SearchContext);
  const {width} = useWindowSize();
  const {t} = useTranslation();

  useEffect(() => {
    if (width <= parseInt(breakpoints.sm)) {
      setIsSearchPanelOpen(true);
    }
  }, []);

  return (
    <HStack justifyContent="center" width="100%">
      <VStack width='70%'>
        <Heading
          as='h1'
          tabIndex={-1}
          variant='Md2Primary'
          mt={px(Spacing08)}
          mb={px(Spacing06)}
        >
          {t('Special Procedures Wales Public Register')}
        </Heading>
        <VStack gap={6}>
          <Text>
            <Trans t={t} i18nKey={'marketing.part-1'}>
            The Special Procedures Licensing Scheme for Wales came into force on 29th November 2024. This public national register provides details of all individuals who are <u>licenced</u> and all premises and vehicles which have been <u>approved</u> under the new Licensing Scheme for the purpose of providing special procedures in Wales. Special procedures are:
            </Trans>
          </Text>
          <UnorderedList sx={{textStyle}} alignSelf="start">
            <ListItem>{t('marketing.licence-type-1')}</ListItem>
            <ListItem>{t('marketing.licence-type-2')}</ListItem>
            <ListItem>{t('marketing.licence-type-3')}</ListItem>
            <ListItem>{t('marketing.licence-type-4')}</ListItem>
          </UnorderedList>
          <Text sx={{textStyle}}>
            <Trans t={t} i18nKey={'marketing.part-2'}>
              Previously registered practitioners and premises will not appear on this register but if they have received a transitional licence and/or transitional premises/vehicle approval certificate from their local authority they can continue to trade while local authorities process their applications under the new scheme. This could take a number of months for local authorities to assess. Information on the transitional arrangements, licensing conditions, and how to apply for a licence is available from your Local Authority and the Welsh Government website. <b>If you would like to check whether a practitioner or business holds a transitional licence, please contact your Local Authority.</b>
            </Trans>
          </Text>
          <Text sx={{textStyle}}>
            <b>{t('Important Information')}</b>
          </Text>
          <UnorderedList sx={{textStyle}} spacing={3}>
            <ListItem>
              <Trans i18nKey={'marketing.info-section-1'} t={t}>
                <Emphasis>A Special Procedure Licence</Emphasis> <StrongEmphasis>does not</StrongEmphasis> provide any assurance of the competence, skill levels, quality of work, or artistic ability of the licence holder performing the special procedure. The Licence confirms the licence holder's knowledge and application of infection prevention and control standards, and first aid relating to their performance of their specified special procedures so as to reduce any risks of harm to clients' health in the performance of those procedures.
              </Trans>
            </ListItem>
            <ListItem>
              <Trans i18nKey="marketing.info-section-2" t={t}>
                <Emphasis>A Premises/Vehicle Approval Certificate</Emphasis> <StrongEmphasis>does not</StrongEmphasis> relate to the quality of work performed by or at that business. The Approval Certificate confirms that the premises/vehicle is of a standard that will enable compliance with the required infection prevention and control standards applicable for the performance of the special procedures within them.
              </Trans>
            </ListItem>
            <ListItem>
              <Trans i18nKey="marketing.info-section-3" t={t}>
                <StrongEmphasis>Exempted Individuals and Individuals to Whom the Licensing Scheme Does Not Apply.</StrongEmphasis> This licensing scheme <StrongEmphasis>does not apply</StrongEmphasis> to the NHS in Wales, therefore medical professionals and healthcare professionals with statutory professional registration and regulation who are employed within the NHS and who may perform one or more of the four specified special procedures as part of their NHS role are not captured by this licensing scheme. These individuals will therefore not feature within this Register.
              </Trans>
            </ListItem>
            <ListItem>
              {t('marketing.info-section-4')}
            </ListItem>
          </UnorderedList>
          <Text sx={{textStyle}}>
            <Trans t={t} i18nKey="marketing.outro">
              <StrongEmphasisLink href={t('marketing.link')} target="_blank">CLICK HERE</StrongEmphasisLink> <Emphasis>for further information on the requirements for Licence Holders and Approval Certificate Holders</Emphasis> (this information provides register users with information on what individuals must fulfil to be able to be granted a licence and/or an approval certificate)
            </Trans>
          </Text>
        </VStack>
        <Button
          onClick={() => setIsSearchPanelOpen(true)}
          w="288px"
          my={px(Spacing06)}
          px={px(Spacing05)}
          py={px(Spacing04)}>
          {t('Let\'s get started')}
        </Button>
      </VStack>
    </HStack>
  )
}

const Emphasis = (props: PropsWithChildren) => <Text sx={{textStyle}} display="inline" fontWeight="bold">{props.children}</Text>;
const StrongEmphasis = (props: PropsWithChildren) => <Text sx={{textStyle}} display="inline" fontWeight="bold" textDecoration="underline">{props.children}</Text>;
const StrongEmphasisLink = (props: LinkProps) => <Link sx={{textStyle}} fontWeight="bold" textDecoration="underline" color='inherit' {...props} />;

export default MarketingPage;
