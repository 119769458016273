{
  "Skip to content": "Yn neidio i'r cynnwys",
  "SPECIAL PROCEDURES": "GWEITHDREFNAU ARBENNIG",
  "PUBLIC REGISTER": "COFRESTR GYHOEDDUS",
  "Search Results": "Canlyniadau chwilio",
  "No results found": "Heb ddod o hyd i ganlyniadau",
  "Close": "Cau",
  "Search for business or practitioner name": "Chwilio am enw busnes neu ymarferydd",
  "Filter": "Hidlo",
  "Local authority": "Awdurdod lleol",
  "Select local authority": "Dewis awdurdod lleol",
  "Procedure type": "Math o weithdrefn",
  "Select a licence": "Dewiswch drwydded",
  "Search": "Chwilio",
  "Tattooing": "Tatŵio",
  "Body piercing": "Tyllu'r corff",
  "Acupuncture": "Aciwbigo",
  "Electrolysis": "Electrolysis",
  "Semi-permanent makeup": "Colur lled-barhaol",
  "Dry needling": "Gosod nodwyddau sych",
  "Certificate holder name": "Enw deiliad y dystysgrif",
  "Approved premises address": "Cyfeiriad safle cymeradwy",
  "Number of workstations": "Nifer y gweithfannau",
  "Show more": "Dangos mwy",
  "Associated business": "Busnes cysylltiedig",
  "Apply": "Gwneud cais",
  "Sign in": "Mewngofnodi",
  "Sign out": "Sign out",
  "Email": "Ebost",
  "Password": "Cyfrinair",
  "Forgotten password?": "Wedi anghofio eich cyfrinair?",
  "Show archive": "Dangos yr archif",
  "Issue date": "Dyddiad cyhoeddi",
  "Bulk Upload": "Uwchlwytho swmp",
  "Continue": "Parhau",
  "Enter practitioner name": "Nodwch enw'r ymarferydd",
  "Practitioner name": "Enw'r ymarferydd",
  "Address line 2": "Llinell 2 y cyfeiriad",
  "Enter town / city here": "Nodwch y dref / dinas yma",
  "Enter address line 2 here": "Nodwch linell 2 y cyfeiriad yma",
  "Address line 1": "Llinell 1 y cyfeiriad ",
  "Town / City": "Tref / Dinas",
  "Enter associated business name here": "Nodwch enw'r busnes cysylltiedig yma",
  "Associated business name": "Enw'r busnes cysylltiedig",
  "Enter postcode here": "Nodwch y cod post yma",
  "Enter address line 1 here": "Nodwch linell 1 y cyfeiriad yma",
  "Add another business": "Ychwanegu busnes arall",
  "Internal notes (optional)": "Nodiadau mewnol (dewisol)",
  "Back": "Yn ôl",
  "Save": "Cadw",
  "Postcode": "Cod post",
  "Licence successfully created": "Trwydded wedi ei chreu'n llwyddiannus",
  "Premises": "Safleoedd",
  "Practitioner": "Ymarferwr",
  "Vehicle": "Cerbyd",
  "Create Single": "Creu sengl",
  "Submit": "Cyflwyno",
  "Success": "Llwyddiant",
  "Bulk upload successful": "Wedi uwchlwytho swmp yn llwyddiannus",
  "Error": "Gwall",
  "Invalid bulk upload content, if you need assistance with uploading data please contact us on {{supportEmail}}": "Cynnwys annilys wedi'i uwchlwytho fel swmp, os oes angen help arnoch i uwchlwytho data, cysylltwch â ni ar {{supportEmail}}",
  "You have been logged out": "Rydych wedi cael eich allgofnodi",
  "Invalid username / password": "Enw defnyddiwr / cyfrinair annilys",
  "There is a problem": "Mae problem wedi codi",
  "A network error occurred": "Mae gwall gyda'r rhwydwaith wedi codi",
  "Licence type": "Math o drwydded",
  "Status": "Statws",
  "Show Less": "Dangos llai",
  "paginationSummary": "Tudalen {{page}} o {{totalPages}}",
  "dateSuffixedArchived": "Archifwyd - {{date}}",
  "dateSuffixedApproved": "Cymeradwywyd - {{date}}",
  "Enter a valid email address": "Nodwch gyfeiriad e-bost dilys",
  "Enter a valid password": "Nodwch gyfrinair dilys",
  "Please select an option": "Dewiswch opsiwn",
  "Please enter a value": "Nodwch werth",
  "Enter a certificate holder name here": "Nodwch enw deiliad y dystysgrif yma",
  "Enter business name here": "Nodwch enw busnes yma",
  "Enter number of workstations here": "Nodwch nifer y gweithfannau yma",
  "Business name": "Enw'r busnes",
  "Please enter a valid number": "Nodwch rif dilys",
  "Licence could not be created": "Nid oedd modd creu trwydded",
  "Unknown error, please try again later": "Gwall anhysbys, rhowch gynnig arall arni yn nes ymlaen",
  "smarter": "smarter",
  "public": "public",
  "protection": "protection",
  "Help": "Help",
  "Accessibility": "Hygyrchedd",
  "Contact": "Cysylltu",
  "Legal": "Cyfreithiol",
  "Copyright": "Hawlfraint",
  "Privacy Policy": "Polisi Preifatrwydd",
  "Terms Conditions": "Telerau ac Amodau",
  "RH Environmental Limited trading as RHE Global. All rights reserved.": "RH Environmental Limited yn masnachu fel RHE Global. Cedwir pob hawl.",
  "Please select a licence type": "Dewis math o drwydded",
  "Please enter the licence number": "Please enter the licence number",
  "Further details can be found": "Mae rhagor o fanylion am y cynllun ar gael",
  "here": "yma",
  "Special Procedures Wales Public Register": "Cofrestr Gyhoeddus Gweithdrefnau Arbennig Cymru",
  "Let's get started": "Gadewch i ni ddechrau",
  "marketing": {
    "part-1": "Daeth Cynllun Trwyddedu Triniaethau Arbennig Cymru i rym ar 29 Tachwedd 2024. Mae'r gofrestr genedlaethol gyhoeddus hon yn rhoi manylion yr holl unigolion sydd wedi'u trwyddedu a'r holl adeiladau a cherbydau sydd wedi cael eu cymeradwyo o dan y Cynllun Trwyddedu newydd er mwyn darparu gweithdrefnau arbennig yng Nghymru. Triniaethau arbennig yw:",
    "licence-type-1": "Aciwbigo (gan gynnwys nodwyddo sych)",
    "licence-type-2": "Tyllu'r corff",
    "licence-type-3": "Electrolysis",
    "licence-type-4": "Tatŵio (gan gynnwys colur lled-barhaol / meicro-llafnu)",
    "part-2": "Ni fydd ymarferwyr a mangreoedd a gofrestrwyd yn flaenorol yn ymddangos ar y gofrestr hon, ond os ydynt wedi cael trwydded drosiannol a/neu dystysgrif cymeradwyo mangre/cerbyd trosiannol gan eu hawdurdod lleol, gallant barhau i fasnachu tra bod awdurdodau lleol yn prosesu eu ceisiadau o dan y cynllun newydd. Gallai hyn gymryd nifer o fisoedd i awdurdodau lleol asesu. Mae gwybodaeth am y trefniadau trosiannol, amodau trwyddedu, a sut i wneud cais am drwydded ar gael gan eich Awdurdod Lleol a gwefan Llywodraeth Cymru. <1>Os hoffech wirio a oes gan ymarferydd neu fusnes drwydded drosiannol, cysylltwch â'ch Awdurdod Lleol.</1>",
    "info-section-1": "Nid yw Trwydded Triniaeth Arbennig yn darparu unrhyw sicrwydd o gymhwysedd, lefelau sgiliau, ansawdd gwaith, neu allu artistig deiliad y drwydded i gyflawni'r weithdrefn arbennig.  Mae'r Drwydded yn cadarnhau gwybodaeth a chymhwysiad deiliad y drwydded o safonau atal a rheoli heintiau, a chymorth cyntaf sy'n ymwneud â'u perfformiad o'u gweithdrefnau arbennig penodedig, er mwyn lleihau unrhyw risgiau o niwed i iechyd cleientiaid wrth gyflawni'r gweithdrefnau hynny.",
    "info-section-2": "Nid yw Tystysgrif Mangre/Cerbyd Cymeradwy yn ymwneud ag ansawdd y gwaith a gyflawnir gan neu yn y busnes hwnnw. Mae'r Dystysgrif Mangre/Cerbyd Cymeradwy yn cadarnhau bod y safle/cerbyd o safon a fydd yn galluogi cydymffurfio â'r safonau atal a rheoli heintiau gofynnol sy'n gymwys ar gyfer cyflawni'r gweithdrefnau arbennig ynddynt.",
    "info-section-3": "Unigolion ac Unigolion sydd wedi'u heithrio nad yw'r Cynllun Trwyddedu yn berthnasol iddynt.   Nid yw'r cynllun trwyddedu hwn yn berthnasol i'r GIG yng Nghymru, felly nid yw gweithwyr meddygol proffesiynol a gweithwyr gofal iechyd proffesiynol, sydd â chofrestru a rheoleiddio proffesiynol statudol sy'n cael eu cyflogi o fewn y GIG ac a allai gyflawni un neu fwy o'r pedair gweithdrefn arbennig benodol fel rhan o'u rôl GIG, yn cael eu cynnwys o fewn y cynllun trwyddedu hwn. Felly ni fydd yr unigolion hyn yn ymddangos yn y Gofrestr hon.",
    "info-section-4": "Yn ogystal, mae yna weithwyr meddygol proffesiynol rheoledig a gweithwyr gofal iechyd proffesiynol a all hefyd berfformio un neu fwy o'r pedair gweithdrefn arbennig benodol yn gyfreithlon, ond ar sail eu cofrestriad a'u rheoleiddio proffesiynol statudol a'r ffaith eu bod yn gweithredu o fewn lleoliad gofal iechyd rheoledig yng Nghymru (megis ysbytai annibynnol rheoledig, clinigau a phractisau deintyddol) maent wedi'u heithrio rhag y gofyniad i gael eu trwyddedu o dan y cynllun trwyddedu hwn i gyflawni'r gweithdrefnau arbennig hynny. Felly ni fydd yr unigolion hyn yn ymddangos yn y Gofrestr hon.",
    "outro": "<0>CLICIWCH YMA</0> i gael rhagor o wybodaeth am y gofynion ar gyfer Deiliaid Trwydded a Deiliaid Tystysgrifau Mangre/Cerbyd Cymeradwy (mae'r wybodaeth hon yn rhoi gwybodaeth i ddefnyddwyr cofrestredig am yr hyn y mae'n rhaid i unigolion ei gyflawni er mwyn gallu cael trwydded a/neu dystysgrif gymeradwyo).",
    "link": "https://signin.riams.org/connect/documents/SKPgn8ON9MvT"
  },
  "Important Information": "Gwybodaeth Bwysig:"
}
