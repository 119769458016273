{
  "Skip to content": "Skip to content",
  "SPECIAL PROCEDURES": "SPECIAL PROCEDURES",
  "PUBLIC REGISTER": "PUBLIC REGISTER",
  "Search Results": "Search Results",
  "No results found": "No results found",
  "Close": "Close",
  "Search for business or practitioner name": "Search for business or practitioner name",
  "Filter": "Filter",
  "Local authority": "Local authority",
  "Select local authority": "Select local authority",
  "Procedure type": "Procedure type",
  "Select a licence": "Select a licence",
  "Search": "Search",
  "Tattooing": "Tattooing",
  "Body piercing": "Body piercing",
  "Acupuncture": "Acupuncture",
  "Electrolysis": "Electrolysis",
  "Semi-permanent makeup": "Semi-permanent makeup",
  "Dry needling": "Dry needling",
  "Certificate holder name": "Certificate holder name",
  "Approved premises address": "Approved premises address",
  "Number of workstations": "Number of workstations",
  "Show more": "Show more",
  "Associated business": "Associated business",
  "Apply": "Apply",
  "Sign in": "Sign in",
  "Sign out": "Sign out",
  "Email": "Email",
  "Password": "Password",
  "Forgotten password?": "Forgotten password?",
  "Show archive": "Show archive",
  "Issue date": "Issue date",
  "Bulk Upload": "Bulk Upload",
  "Continue": "Continue",
  "Enter practitioner name": "Enter practitioner name",
  "Practitioner name": "Practitioner name",
  "Address line 2": "Address line 2",
  "Enter town / city here": "Enter town / city here",
  "Enter address line 2 here": "Enter address line 2 here",
  "Address line 1": "Address line 1",
  "Town / City": "Town / City",
  "Enter associated business name here": "Enter associated business name here",
  "Associated business name": "Associated business name",
  "Enter postcode here": "Enter postcode here",
  "Enter address line 1 here": "Enter address line 1 here",
  "Add another business": "Add another business",
  "Internal notes (optional)": "Internal notes (optional)",
  "Back": "Back",
  "Save": "Save",
  "Postcode": "Postcode",
  "Licence successfully created": "Licence successfully created",
  "Premises": "Premises",
  "Practitioner": "Practitioner",
  "Vehicle": "Vehicle",
  "Create Single": "Create Single",
  "Submit": "Submit",
  "Success": "Success",
  "Bulk upload successful": "Bulk upload successful",
  "Error": "Error",
  "Invalid bulk upload content, if you need assistance with uploading data please contact us on {{supportEmail}}": "Invalid bulk upload content, if you need assistance with uploading data please contact us on {{supportEmail}}",
  "You have been logged out": "You have been logged out",
  "Invalid username / password": "Invalid username / password",
  "There is a problem": "There is a problem",
  "A network error occurred": "A network error occurred",
  "Licence type": "Licence type",
  "Status": "Status",
  "Show Less": "Show Less",
  "paginationSummary": "Page {{page}} of {{totalPages}}",
  "dateSuffixedArchived": "Archived - {{date}}",
  "dateSuffixedApproved": "Approved - {{date}}",
  "Enter a valid email address": "Enter a valid email address",
  "Enter a valid password": "Enter a valid password",
  "Please select an option": "Please select an option",
  "Please enter a value": "Please enter a value",
  "Enter a certificate holder name here": "Enter a certificate holder name here",
  "Enter business name here": "Enter business name here",
  "Enter number of workstations here": "Enter number of workstations here",
  "Business name": "Business name",
  "Please enter a valid number": "Please enter a valid number",
  "Licence could not be created": "Licence could not be created",
  "Unknown error, please try again later": "Unknown error, please try again later",
  "smarter": "smarter",
  "public": "public",
  "protection": "protection",
  "Help": "Help",
  "Accessibility": "Accessibility",
  "Contact": "Contact",
  "Legal": "Legal",
  "Copyright": "Copyright",
  "Privacy Policy": "Privacy Policy",
  "Terms Conditions": "Terms and Conditions",
  "RH Environmental Limited trading as RHE Global. All rights reserved.": "RH Environmental Limited trading as RHE Global. All rights reserved.",
  "Please select a licence type": "Please select a licence type",
  "Please enter the licence number": "Please enter the licence number",
  "marketing": {
    "part-1": "The Special Procedures Licensing Scheme for Wales came into force on 29th November 2024. This public national register provides details of all individuals who are <1>licenced</1> and all premises and vehicles which have been <3>approved</3> under the new Licensing Scheme for the purpose of providing special procedures in Wales. Special procedures are: ",
    "licence-type-1": "Acupuncture (including dry needling)",
    "licence-type-2": "Body piercing",
    "licence-type-3": "Electrolysis",
    "licence-type-4": "Tattooing (including semi-permanent make-up / microblading)",
    "part-2": "Previously registered practitioners and premises will not appear on this register but if they have received a transitional licence and/or transitional premises/vehicle approval certificate from their local authority they can continue to trade while local authorities process their applications under the new scheme. This could take a number of months for local authorities to assess. Information on the transitional arrangements, licensing conditions, and how to apply for a licence is available from your Local Authority and the Welsh Government website. <1>If you would like to check whether a practitioner or business holds a transitional licence, please contact your Local Authority</1>.",
    "info-section-1": "<0>A Special Procedure Licence</0> <2>does not</2> provide any assurance of the competence, skill levels, quality of work, or artistic ability of the licence holder performing the special procedure. The Licence confirms the licence holder's knowledge and application of infection prevention and control standards, and first aid relating to their performance of their specified special procedures so as to reduce any risks of harm to clients' health in the performance of those procedures.",
    "info-section-2": "<0>A Premises/Vehicle Approval Certificate</0> <2>does not</2> relate to the quality of work performed by or at that business. The Approval Certificate confirms that the premises/vehicle is of a standard that will enable compliance with the required infection prevention and control standards applicable for the performance of the special procedures within them.",
    "info-section-3": "<0>Exempted Individuals and Individuals to Whom the Licensing Scheme Does Not Apply.</0> This licensing scheme <2>does not apply</2> to the NHS in Wales, therefore medical professionals and healthcare professionals with statutory professional registration and regulation who are employed within the NHS and who may perform one or more of the four specified special procedures as part of their NHS role are not captured by this licensing scheme. These individuals will therefore not feature within this Register.",
    "info-section-4": "Additionally, there are regulated medical professionals and healthcare professionals who may also legitimately perform one or more of the four specified special procedures, but on the basis of their statutory professional registration and regulation and the fact they operate from within a regulated healthcare setting in Wales (such as regulated independent hospitals, clinics and dental practices) they are exempt from the requirement to be licenced under this licensing scheme to perform those special procedures. These individuals will therefore not feature within this Register.",
    "outro": "<0>CLICK HERE</0> <2>for further information on the requirements for Licence Holders and Approval Certificate Holders</2> (this information provides register users with information on what individuals must fulfil to be able to be granted a licence and/or an approval certificate)",
    "link": "https://riams.org/connect/documents/iqlbcUWFB07X"
  },
  "Further details can be found": "Further details can be found",
  "here": "here",
  "Let's get started": "Let's get started"
}
